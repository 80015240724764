import 'mdui/components/text-field.js';
import 'mdui/components/button.js';
import 'mdui/components/list.js';
import 'mdui/components/list-item.js';
import './login.css';
import Carousel from '../../components/carousel/carousel.js';

import { useState } from 'react';

const Login = () =>{
    const mainTitle = "Descubre los negocios que crecen con yenti";
    const images = [
        {
          src: 'images/artesanos.webp',
          title: 'Artesanos',
          subtitle: 'Vende tus artesanias digital y presencialmente',
        },
        {
          src: 'images/guias_turisticos.webp',
          title: 'Guías turísticos',
          subtitle: 'Ofrece tus recorridos y gestiona su asistencia',
        },
        {
          src: 'images/profesora_de_salsa.webp',
          title: 'Clases de baile y danzas',
          subtitle: 'Ofrece tus cursos y gestiona su asistencia',
        },
    ];

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleLogin = async () => {
        try {
          const response = await fetch('http://localhost:3001/users');
          const users = await response.json();
          const user = users.find(
            (user) =>
              user.email === email.trim() && user.password === password.trim()
          );
          if (user) {
            alert('Login exitoso');
            setError(null);
            alert('ok');
          } else {
            setError('Correo electrónico o contraseña incorrectos');
          }
        } catch (error) {
          setError('Hubo un error al intentar iniciar sesión');
        }
    };

    return(
        <>
        <div className="flex-container">
            <div className="login banner">
                <div className="carrousel-area">
                    <h1 className="main-title">{mainTitle}</h1>
                    <Carousel images={images} play />   
                </div>
            </div>
            <div className="mdui-prose login form">
                <div className="text-align-center mgt32 mgb32">
                    <img alt="Logotipo" src="images/logo 64.png" />
                    <h3>Ingresar</h3>
                </div>
                <div className='form-container'>
                    <mdui-text-field 
                        variant="outlined"
                        onInput={(e) => setEmail(e.target.value)}
                        label="Correo Electrónico"
                        clearable
                        autofocus>
                    </mdui-text-field>
                    <mdui-text-field 
                        variant="outlined" 
                        onInput={(e) => setPassword(e.target.value)}
                        type="password"
                        label="Contraseña"
                        toggle-password>
                    </mdui-text-field>
                    <mdui-button full-width onClick={handleLogin}>
                        Ingresar
                        <mdui-icon slot="icon" name="check--outlined"></mdui-icon>
                    </mdui-button>
                    {error && <p className="error">{error}</p>}
                </div>
                <div className='mgt32 text-align-center'>
                    <mdui-button variant="text">
                        ¿Olvidaste tu contraseña?
                    </mdui-button>
                </div>
                <div className="mgt32"> 
                    <mdui-list>
                        <mdui-list-item>
                            <mdui-avatar slot="icon" src="images/PlayStore.png"></mdui-avatar>
                            <span className='text-primary'>Prueba la nueva App de Yenti</span>
                            <span slot="description">Descárgala desde la Play Store</span>
                        </mdui-list-item>
                    </mdui-list>                
                </div>
            </div>
        </div>
        </>
    )
}

export default Login;