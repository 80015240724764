import './App.css';
import 'mdui';
import 'mdui/mdui.css';
import 'mdui/components/icon.js';
//import Navigation from './layout/navigation';
//import Content from './layout/content';
import Login from './features/auth/login';

function App() {
  return (
    <div className="App">
      <div>
        <Login></Login>
      </div>
    </div>
  );
}

export default App;
