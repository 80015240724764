// src/carousel.js
import React, { useState, useEffect, useCallback } from 'react';
import './carousel.css';

const Carousel = ({ images, play }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(play !== undefined && play !== null);
  const [progress, setProgress] = useState(0);
  const intervalTime = 3000; // Tiempo para cambiar de imagen (3 segundos)
  const [loading, setLoading] = useState(true);

  const resetProgressBar = () => {
    setProgress(0);
  };

  const goToPrevious = useCallback(() => {
    resetProgressBar();
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  }, [images.length]);

  const goToNext = useCallback(() => {
    resetProgressBar();
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  }, [images.length]);

  useEffect(() => {
    let interval;
    let progressInterval;

    setTimeout(() => {
      setLoading(false);
    }, 500); // Ajusta el tiempo según sea necesario

    if (isPlaying) {
      resetProgressBar(); // Reinicia el progreso al iniciar el autoplay

      // Intervalo para cambiar de imagen
      interval = setInterval(() => {
        goToNext();
      }, intervalTime);

      // Incrementa la barra de progreso en intervalos pequeños, sincronizados con el tiempo total
      progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 100 / (intervalTime / 50); // Calcula el incremento necesario
          if (newProgress >= 100) {
            resetProgressBar(); // Reinicia el progreso cuando llega al 100%
            return 100; // Asegura que no pase del 100%
          }
          return newProgress;
        });
      }, 50); // Actualiza el progreso cada 50ms

      return () => {
        clearInterval(interval);
        clearInterval(progressInterval);
      };
    }
  }, [isPlaying, currentIndex, goToNext]);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    resetProgressBar(); // Reinicia el progreso cuando se pausa o reanuda
  };

  return (
    <div className="carousel">
      {loading && (
        <div className="preloader"></div> // Aquí se coloca el preloader
      )}
      {!loading && (
        <>
          <button className="carousel-button prev" onClick={goToPrevious}>
            ❮
          </button>
          <div
            className="carousel-image-container"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {images.map((image, index) => (
              <div key={index} className="carousel-slide">
                <img
                  src={image.src}
                  alt={`Slide ${index}`}
                  className="carousel-image"
                  onClick={() => setCurrentIndex(index)}
                  onLoad={() => setLoading(false)} // Para ocultar el preloader cuando la imagen carga
                />
                <div className="carousel-caption">
                  <h2>{image.title}</h2>
                  <p>{image.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-button next" onClick={goToNext}>
            ❯
          </button>
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progress}%` }} />
          </div>
          <button className="pause-button" onClick={togglePlay}>
            {isPlaying ? 'Pausa' : 'Play'}
          </button>
        </>
      )}
    </div>
  );
};

export default Carousel;
